import React, { useState } from 'react'
import { Alert } from 'react-bootstrap'

const ShowInfo = (props) => {
    if (props.show) {
        return (
            <Alert variant={props.variant} onClose={() => props.closeAlert} dismissible>
                <Alert.Heading>{ props.title }</Alert.Heading>
                <p>{ props.text }</p>
            </Alert>
        );
    } else {
        return null
    }
}

export default ShowInfo