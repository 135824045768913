import styled from "styled-components";

export default styled.footer`
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 60px;
    line-height: 60px;
    background-color: #343A40;
    color: #FFF;
    margin-top: 40px;
`;