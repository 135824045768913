import React from 'react'

import Main from './style'

const MainContentSpaced = (props) => {
    return (
        <Main>
            {props.children}
        </Main>
    )
}

export default MainContentSpaced